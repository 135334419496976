<template>
  <svg
    width="82"
    height="22"
    viewBox="0 0 82 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.5H13V4.84171H0V1.5ZM0 20.5V10.0256H12.1379V13.3729H4.14943V20.5H0Z"
      fill="#000" />
    <path
      d="M25.6987 21.2106C31.7218 21.2106 35.2932 17.4871 35.2932 11.3135C35.2932 5.15916 31.7218 1.45498 25.8339 1.45498H19V21.2106H25.6987ZM22.581 18.1141V4.55145H25.6312C29.6466 4.55145 31.7411 6.78939 31.7411 11.3135C31.7411 15.8569 29.6466 18.1141 25.525 18.1141H22.581Z"
      fill="#000" />
    <path
      d="M37.8617 21.2106H41.3558V12.5C41.3558 10.619 42.7747 9.28778 44.6956 9.28778C45.2843 9.28778 46.0179 9.39389 46.3171 9.49035V6.27813C45.9986 6.22026 45.4484 6.18167 45.0623 6.18167C43.3635 6.18167 41.9446 7.1463 41.4041 8.86334H41.2497V6.39389H37.8617V21.2106Z"
      fill="#000" />
    <path
      d="M47.959 21.2106H51.4532V6.39389H47.959V21.2106ZM49.7157 4.291C50.8258 4.291 51.7331 3.44212 51.7331 2.40032C51.7331 1.34887 50.8258 0.5 49.7157 0.5C48.5961 0.5 47.6887 1.34887 47.6887 2.40032C47.6887 3.44212 48.5961 4.291 49.7157 4.291Z"
      fill="#000" />
    <path
      d="M67.6098 6.39389H63.8936L60.4767 17.4003H60.3222L56.915 6.39389H53.1891L58.469 21.2106H62.3299L67.6098 6.39389Z"
      fill="#000" />
    <path
      d="M75.4074 21.5C78.863 21.5 81.2375 19.8119 81.8552 17.2363L78.5927 16.8698C78.1197 18.1238 76.9615 18.7797 75.4557 18.7797C73.197 18.7797 71.7009 17.2942 71.672 14.7572H82V13.6865C82 8.48714 78.8726 6.20096 75.224 6.20096C70.977 6.20096 68.2068 9.31672 68.2068 13.8891C68.2068 18.5386 70.9384 21.5 75.4074 21.5ZM71.6816 12.4035C71.7878 10.5129 73.1874 8.92122 75.2723 8.92122C77.28 8.92122 78.6313 10.3875 78.6506 12.4035H71.6816Z"
      fill="#000" />
  </svg>
</template>
